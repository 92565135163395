import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import ChatStore from "../stores/chat.store"
import SEO from "../components/SEO"
import Chat from "../components/Chat"
import Image from "gatsby-image"
import { Provider } from "mobx-react"
import BlogShare from "../components/BlogShare"
import Breadcrumbs from "../components/Breadcrumbs"

class BlogPost extends React.Component {

    constructor(props) {
        super(props)
        this.stores = {
            chat: new ChatStore()
        }
    }


    render() {
        const { post } = this.props.data
        const showBackdrop = post.frontmatter.backdrop.childImageSharp.aspectRatio === 4 / 1
        return (
            <Provider
                chat_store={this.stores.chat}
            >
                <Layout>
                    <SEO title={post.frontmatter.title} />

                    <Chat />
                    <div className="top-offset">
                        {showBackdrop ? (
                            <Image alt={post.frontmatter.title} fluid={{ ...post.frontmatter.backdrop.childImageSharp.fluid, aspectRatio: 4 / 1 }} />
                        ) : null}
                        <article className="position-relative">
                            <div className="post">
                                <Breadcrumbs paths={[{ url: "/blog", text:"blog" }]} />
                                <h1>{post.frontmatter.title}</h1>
                            </div>
                            <BlogShare url={post.fields.slug} />
                            <div className="post" dangerouslySetInnerHTML={{ __html: post.html }} />
                        </article>
                    </div>
                </Layout >
            </Provider>
        )
    }
}

export default BlogPost

export const BlogPostQuery = graphql`
    query BlogPostQuery($id: String!) {
        post: markdownRemark(id: { eq: $id}) {
            fields {
              slug
            }
            id
            html
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              backdrop {
                  childImageSharp {
                      fluid(maxWidth: 700) {
                        ...GatsbyImageSharpFluid
                    }
                  }
              }
            }
          }
    }

`