import React from "react"
import styles from "./style.module.css"
import { Link } from "gatsby"

const Breadcrumbs = ({ paths }) => (
    <small className={styles.wrapper}>{paths.map((p, i) => (
        <Link key={i} to={p.url}>/{p.text}</Link>
    ))}
    </small>
)

export default Breadcrumbs