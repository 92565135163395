import React from "react"
import PropTypes from "prop-types"
import style from "./style.module.css"
import classnames from "classnames"

class Channel extends React.Component {

    async componentDidMount() {
        if (!window.Stickyfill) {
            window.Stickyfill = await import("stickyfilljs")
        }
        const elm = document.getElementById(this.props.id)
        if (elm){
            window.Stickyfill.addOne(elm)
        }
    }

    componentWillUnmount() {
        const elm = document.getElementById(this.props.id)
        if (elm && window.Stickyfill) {
            window.Stickyfill.removeOne(elm)
        }
    }

    render() {

        return (
            <div className={classnames(`h-100 w-100`, this.props.className)}>
                <div id={this.props.id} style={{ top: this.props.top ? this.props.top : "0px" }} className={style.sticky}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

Channel.propTypes = {
    id: PropTypes.string.isRequired,
    top: PropTypes.string,
    className: PropTypes.string,
}

export default Channel