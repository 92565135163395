import React from "react"
import classnames from "classnames"
import styles from "./style.module.css"
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon, RedditShareButton, RedditIcon, EmailShareButton, EmailIcon, TumblrShareButton, TumblrIcon } from "react-share"
import Channel from "../Channel"

const BlogShare = ({ className, url, onShare }) => {
    url = `${process.env.SITE_URL}${url}`
    return (
        <div className={classnames(styles.wrapper, className, "d-flex justify-content-center")}>
            <Channel id="blog-share" top="35vh" className={classnames(styles.inner)}>
                <FacebookShareButton url={url} openShareDialogOnClick={() => onShare("facebook")}>
                    <FacebookIcon />
                </FacebookShareButton>
                <TwitterShareButton url={url} openShareDialogOnClick={() => onShare("twitter")}>
                    <TwitterIcon />
                </TwitterShareButton>
                <LinkedinShareButton url={url} openShareDialogOnClick={() => onShare("linkedin")}>
                    <LinkedinIcon />
                </LinkedinShareButton>
                <EmailShareButton url={url} openShareDialogOnClick={() => onShare("email")}>
                    <EmailIcon />
                </EmailShareButton>
                <RedditShareButton url={url} openShareDialogOnClick={() => onShare("reddit")}>
                    <RedditIcon />
                </RedditShareButton>
                <TumblrShareButton url={url} openShareDialogOnClick={() => onShare("tumblr")}>
                    <TumblrIcon />
                </TumblrShareButton>
            </Channel>
        </div>
    )
}

export default BlogShare